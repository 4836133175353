














































import { Prop, Vue, Component } from 'vue-property-decorator'

@Component
export default class BaseLinearProgress extends Vue {
  @Prop({
    required: true,
    type: Number,
  }) readonly value!: number

  @Prop({
    required: false,
    default: '8px',
    type: String,
  }) readonly size!: string

  @Prop({
    required: false,
    default: '',
    type: String,
  }) readonly title!: string

  @Prop({
    required: false,
    default: '',
    type: String,
  }) readonly progress!: string

  @Prop({
    required: false,
    default: false,
    type: Boolean,
  }) readonly hasError!: boolean

  get percent () {
    return `${Math.ceil(this.value * 100)}%`
  }

  get color () {
    if (this.hasError) return 'base-error'
    return this.value >= 1 ? 'base-success' : 'base-brand'
  }

  get linearValue () {
    return !this.hasError ? this.value : 100
  }

  get trackColor () {
    return !this.hasError ? 'bg-substrate-background' : 'base-error-light'
  }
}
