var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"redesign base-linear-progress",class:{
    'base-linear-progress__error': _vm.hasError
  }},[_c('q-item-label',{staticClass:"base-linear-progress__title",class:{
      'base-linear-progress__error': _vm.hasError
    },attrs:{"lines":"1"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('q-linear-progress',{staticClass:"td-rounded-8 base-linear-progress__linear",attrs:{"size":_vm.size,"value":_vm.linearValue,"color":_vm.color,"track-color":_vm.trackColor}},[_vm._t("default")],2),_c('div',{staticClass:"row justify-between items-baseline fit no-wrap base-linear-progress__bar",class:{
      'base-linear-progress__error': _vm.hasError
    }},[_c('q-item-label',{attrs:{"lines":"1"}},[_vm._v(" "+_vm._s(_vm.progress)+" ")]),_c('q-item-label',{class:{
        'base-linear-progress__error': _vm.hasError
      },attrs:{"lines":"1"}},[_vm._v(" "+_vm._s(_vm.hasError ? _vm.$t('import.errorCaption').toString() : _vm.percent)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }